<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('program_definitions')"
                        :is-filter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('program_definitions')"
                              :is-filter="false" />
            </template>
            <b-row v-for="(faculty, index) in faculties" :key="index">
                <b-col md="12">
                    <b-button variant="link"
                              @click="getPrograms(faculty.code, index)"
                              ><b>{{faculty.code+' - '+getLocaleText(faculty, 'name')}}</b></b-button>
                    <b-collapse :id="`faculty-${index}`" accordion="my-accordion">
                        <div v-if="facultyColl == index">
                            <b-col md="12" class="ml-3" v-for="(program, key) in programs" :key="key">
                                <b-button variant="link" v-b-toggle="`program-${key}`"><span class="text-black-75">{{program.code+' - '+getLocaleText(program, 'name')}}</span></b-button>
                                <b-collapse :id="`program-${key}`" :accordion="`my-accordion-2-${index}`">
                                    <b-col md="12" class="ml-4">
                                        <b-button variant="link" @click="openDefinitions(program, 'update-form')"><span class="text-black-50">{{ '1. '+$t('program_definitions') }}</span></b-button>
                                    </b-col>
                                    <b-col md="12" class="ml-4">
                                        <b-button variant="link" @click="openDefinitions(program, 'outcomes')"><span class="text-black-50">{{ '2. '+$t('program_outcome') }}</span></b-button>
                                    </b-col>
                                    <b-col md="12" class="ml-4">
                                        <b-button variant="link" @click="openDefinitions(program, 'basic-fields')"><span class="text-black-50">{{ '3. '+$t('program_basic_fields') }}</span></b-button>
                                    </b-col>
                                </b-collapse>
                            </b-col>
                        </div>
                    </b-collapse>
                </b-col>
            </b-row>

            <CommonModal ref="updateModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="closeDefinitions">
                <template v-slot:CommonModalTitle>
                    {{ updateModalTitle }}
                </template>
                <template v-slot:CommonModalContent>
                    <component v-if="programData" :program="programData" @closeModal="closeDefinitions" :is="isPage"></component>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import CommonModal from "@/components/elements/CommonModal"
// Pages

// Services
import FacultyService from "@/services/FacultyService"
import ProgramService from "@/services/ProgramService";

// Others
import qs from 'qs'

// Pages
import UpdateForm from "./UpdateForm"
import Outcomes from "./Outcomes"
import BasicFields from "./BasicFields"


export default {
    components: {
        AppLayout,
        Header,
        CommonModal,
        UpdateForm,
        Outcomes,
        BasicFields
    },
    metaInfo() {
        return {
            title: this.$t('program_definitions')
        }
    },
    data() {
        return {
            faculties: null,
            programs: null,
            facultyColl: null,
            updateModalTitle: null,
            programData: null,
            isPage: null
        }
    },
    created() {
        this.getFaculties()
    },
    methods: {
        getFaculties(){
            const config = {
                params: {
                    limit: -1
                }
            }
            FacultyService.getAll(config).then(response => {
               this.faculties = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        getPrograms(facultyCode, index) {
            this.programs = null
            this.facultyColl = index
            this.$root.$emit('bv::toggle::collapse', 'faculty-'+index)
            const config = {
                params: {
                    filter: {
                        faculty_code: facultyCode
                    },
                    sort: 'code',
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            ProgramService.getAll(config).then(response => {
                this.programs = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        openDefinitions(program, page){
            this.isPage = page
            this.programData = program
            this.updateModalTitle = program.code+' - '+this.getLocaleText(program, 'name')
            this.$refs.updateModal.$refs.commonModal.show()
        },

        closeDefinitions(){
            this.isPage = null
            this.programData = null
            this.$refs.updateModal.$refs.commonModal.hide()
        }
    }
}
</script>

