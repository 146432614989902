<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('faculty_code')">
                                    {{ formData.faculty_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('department_code')">
                                    {{ formData.department_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_level')">
                                    {{ getLocaleText(formData, 'level_text') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_duration')">
                                    {{ formData.duration }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row v-if="isNew">
            <b-col md="12" class="mt-2">
                <b-card :header="$t('new').toUpper()+' '+$t('program_outcome_definition_name').toUpper()" header-tag="header">
                    <ValidationObserver ref="formValidate">
                        <b-row>
                            <b-col sm="12" md="6" v-if="isDefinition">
                                <ValidationProvider name="program_outcome_definition_id" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('program_outcome_definition_text')">
                                        <program-outcome-definition-selectbox
                                            v-model="newFormData.program_outcome_definition_id"
                                            :validate-error="errors[0]"/>
                                        <b-form-text id="input-live-help">
                                            {{ $t('for_new') }} <a href="#" @click="openDefinitionBox">{{ $t('clicking') }}</a>
                                        </b-form-text>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="tyyc_basic_field_type" v-slot="{ errors }">
                                    <b-form-group :label="$t('basic_field_type')">
                                        <parameter-selectbox
                                            v-model="newFormData.tyyc_basic_field_type"
                                            code="tyyc_basic_field_types"
                                            :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="rank" rules="" v-slot="{ errors }">
                                    <b-form-group :label="$t('rank')">
                                        <b-form-input type="number" v-model="newFormData.rank" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="6">
                                <ValidationProvider name="show_in_diploma_supplement" rules="" v-slot="{ errors }">
                                    <b-form-group :label="$t('show_in_diploma_supplement')">
                                        <status-selectbox
                                            :valueType="'true'"
                                            v-model="newFormData.show_in_diploma_supplement"
                                            :validateError="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12" class="d-flex justify-content-center mt-2">
                                <b-button variant="primary" @click="save" :disabled="formLoading">{{ $t("save") }}</b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card>
                    <b-row>
                        <b-col md="12">
                            <b-button variant="primary" @click="newOutcome">{{ $t('new') }}</b-button>
                        </b-col>
                        <b-col md="12">
                            <b-table
                                :empty-filtered-text="$t('no_result')"
                                :empty-text="$t('no_result')"
                                bordered
                                striped
                                responsive
                                :items="tableData"
                                :fields="tableFields"
                                show-empty
                                class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                <template #cell(show_in_diploma_supplement)="row">
                                    {{ row.item.show_in_diploma_supplement == true ? $t('yes') : $t('no') }}
                                </template>
                                <template #cell(buttons)="row">
                                    <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <div id="dropdownListHead">
                                            <b-dropdown-item @click="openUpdateBox(row.item)">
                                                <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="deleteOutcome(row.item)">
                                                <i class="ri-delete-bin-2-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <CommonModal ref="definitonModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="closeDefinitions">
            <template v-slot:CommonModalTitle>
                {{ $t('new') }}
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ValidationObserver ref="definitionValidate">
                        <b-row>
                            <b-col md="12">
                                <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                    <b-form-group :label="$t('program_outcome_definition_name')">
                                        <b-form-textarea
                                            rows="4"
                                            v-model="definitionForm.name"
                                            :state="errors[0] ? false : null">
                                        </b-form-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="12">
                                <ValidationProvider name="name_en" v-slot="{ errors }" rules="required">
                                    <b-form-group :label="$t('program_outcome_definition_name')+' (EN)'">
                                        <b-form-textarea
                                            rows="4"
                                            v-model="definitionForm.name_en"
                                            :state="errors[0] ? false : null">
                                        </b-form-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="12" class="d-flex justify-content-center mt-2">
                                <b-button @click="saveDefinition" variant="primary">{{ $t('save') }}</b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
            </template>
        </CommonModal>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import ProgramOutcomeDefinitionSelectbox from "@/components/interactive-fields/ProgramOutcomeDefinitionSelectbox"
import CommonModal from "@/components/elements/CommonModal"
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox"

// Services
import ProgramService from "@/services/ProgramService"
import ProgramOutcome from "@/services/ProgramOutcome"
import ProgramOutcomeDefinition from "@/services/ProgramOutcomeDefinition"


// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        ProgramOutcomeDefinitionSelectbox,
        ParameterSelectbox,
        CommonModal,
        StatusSelectbox
    },
    props: {
        program: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            tableData: [],
            tableFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: 'rank',
                    label: this.$t('order_tr_en')
                },
                {
                    key: this.getLocaleField('tyyc_basic_field_type_name'),
                    label: this.$t('basic_field_type')
                },
                {
                    key: 'show_in_diploma_supplement',
                    label: this.$t('show_in_diploma_supplement')
                },
                {
                    key: 'program_outcome_definition_name',
                    label: this.$t('program_outcome_definition_name')
                },
                {
                    key: 'program_outcome_definition_name_en',
                    label: this.$t('program_outcome_definition_name')+' (EN)'
                },
            ],
            isNew: false,
            newFormData: {},
            isDefinition: true,
            definitionForm: {}
        };
    },
    created() {
        this.formData = this.program
        this.getData()
    },
    methods: {
        getData(){
            const config = {
                params: {
                    filter: {
                        program_code: this.program.code
                    },
                    sort: 'id',
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            ProgramOutcome.getAll(config).then(response => {
                this.tableData = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        newOutcome() {
            this.newFormData = {}
            return this.isNew = !this.isNew
        },

        openDefinitionBox(){
            this.$refs.definitonModal.$refs.commonModal.show()
            this.isDefinition = false
            this.definitionForm = {}
        },

        closeDefinitions(){
            this.isDefinition = true
        },

        async saveDefinition(){
            const isValid = await this.$refs.definitionValidate.validate();
            if (isValid) {
                ProgramOutcomeDefinition.store(this.definitionForm).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.newFormData.program_outcome_definition_id = response.data.data.id
                    this.$refs.definitonModal.$refs.commonModal.hide()
                    this.closeDefinitions()
                }).catch(e => {
                    this.showErrors(e, this.$refs.definitionValidate);
                })
            }
        },

        async save(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formLoading = true
                this.newFormData.program_code = this.program.code
                if(this.newFormData.id) {
                    ProgramOutcome.update(this.newFormData.id, this.newFormData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.formLoading = false
                        this.getData()
                        this.isNew = false
                        this.newFormData = {}
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate);
                        this.formLoading = false
                    })
                } else {
                    ProgramOutcome.store(this.newFormData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.formLoading = false
                        this.getData()
                        this.isNew = false
                        this.newFormData = {}
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate);
                        this.formLoading = false
                    })
                }
            }
        },

        openUpdateBox(data){
            this.isNew = true
            data.show_in_diploma_supplement = data.show_in_diploma_supplement == true ? 1 : 0
            this.newFormData = data
        },

        deleteOutcome(data){
            this.$swal
                .fire({
                    title: this.$t('attention'),
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        ProgramOutcome.del(data.id).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.getData()
                        }).catch(e => {
                            this.showErrors(e)
                        })
                    }
                })
        }

    },
}
</script>
