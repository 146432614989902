<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('faculty_code')">
                                    {{ formData.faculty_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('department_code')">
                                    {{ formData.department_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_level')">
                                    {{ getLocaleText(formData, 'level_text') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_duration')">
                                    {{ formData.duration }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="general_info" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('general_info')">
                                        <b-form-textarea v-model="formData.general_info"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="general_info_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('general_info')+' (EN)'">
                                        <b-form-textarea v-model="formData.general_info_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="profile" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('profile')">
                                        <b-form-textarea v-model="formData.profile"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="profile_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('profile')+' (EN)'">
                                        <b-form-textarea v-model="formData.profile_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="admission_requirements" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('admission_requirements')">
                                        <b-form-textarea v-model="formData.admission_requirements"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="admission_requirements_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('admission_requirements')+' (EN)'">
                                        <b-form-textarea v-model="formData.admission_requirements_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="recognition_of_prior_learning" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('recognition_of_prior_learning')">
                                        <b-form-textarea v-model="formData.recognition_of_prior_learning"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="recognition_of_prior_learning_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('recognition_of_prior_learning')+' (EN)'">
                                        <b-form-textarea v-model="formData.recognition_of_prior_learning"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="occupational_profiles_of_graduates" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('occupational_profiles_of_graduates')">
                                        <b-form-textarea v-model="formData.occupational_profiles_of_graduates"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="occupational_profiles_of_graduates_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('occupational_profiles_of_graduates')+' (EN)'">
                                        <b-form-textarea v-model="formData.occupational_profiles_of_graduates_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="access_to_further_studies" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('access_to_further_studies')">
                                        <b-form-textarea v-model="formData.access_to_further_studies"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="access_to_further_studies_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('access_to_further_studies')+' (EN)'">
                                        <b-form-textarea v-model="formData.access_to_further_studies_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="vision_and_mission" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('vision_and_mission')">
                                        <b-form-textarea v-model="formData.vision_and_mission"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="vision_and_mission_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('vision_and_mission')+' (EN)'">
                                        <b-form-textarea v-model="formData.vision_and_mission_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="web_address" rules="" v-slot="{errors, valid}">
                                    <b-form-group :label="$t('web_address')">
                                        <b-form-input
                                            type="text"
                                            v-model="formData.web_address"
                                            :state="errors[0] ? false : (valid ? true : null)" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="show_in_information_package" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('show_in_information_package')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group v-model="formData.show_in_information_package" :aria-describedby="ariaDescribedby">
                                            <b-form-radio value="1">{{ $t('yes') }}</b-form-radio>
                                            <b-form-radio value="0">{{ $t('no') }}</b-form-radio>
                                        </b-form-radio-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="show_in_coordinators_page" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('show_in_coordinators_page')" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group v-model="formData.show_in_coordinators_page" :aria-describedby="ariaDescribedby">
                                            <b-form-radio value="1">{{ $t('yes') }}</b-form-radio>
                                            <b-form-radio value="0">{{ $t('no') }}</b-form-radio>
                                        </b-form-radio-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button
                    variant="primary"
                    @click="save"
                    :disabled="formLoading">
                    {{ $t("save") }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components

// Services
import ProgramService from "@/services/ProgramService";

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        program: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
        };
    },
    created() {
        this.formData = this.program
    },
    methods: {
        async save(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formLoading = true
                ProgramService.updateDefinitions(this.formData, this.program.code).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.$emit('closeModal', true)
                    this.formLoading = false
                }).catch(e => {
                    this.showErrors(e, this.$refs.formValidate)
                    this.formLoading = false
                })
            }
        }
    },
}
</script>
