<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('faculty_code')">
                                    {{ formData.faculty_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('department_code')">
                                    {{ formData.department_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_level')">
                                    {{ getLocaleText(formData, 'level_text') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_duration')">
                                    {{ formData.duration }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row v-if="isNew">
            <b-col md="12" class="mt-2">
                <b-card :header="$t('new').toUpper()+' '+$t('program_basic_field').toUpper()" header-tag="header">
                    <ValidationObserver ref="formValidate">
                        <b-row>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="tyyc_basic_field" v-slot="{ errors }">
                                    <b-form-group :label="$t('tyyc_basic_field')">
                                        <parameter-selectbox
                                            v-model="newFormData.tyyc_basic_field"
                                            code="tyyc_basic_fields"
                                            :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12" class="d-flex justify-content-center mt-2">
                                <b-button variant="primary" @click="save" :disabled="formLoading">{{ $t("save") }}</b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card>
                    <b-row>
                        <b-col md="12">
                            <b-button variant="primary" @click="newOutcome">{{ $t('new') }}</b-button>
                        </b-col>
                        <b-col md="12">
                            <b-table
                                :empty-filtered-text="$t('no_result')"
                                :empty-text="$t('no_result')"
                                bordered
                                striped
                                responsive
                                :items="tableData"
                                :fields="tableFields"
                                show-empty
                                class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                <template #cell(show_in_diploma_supplement)="row">
                                    {{ row.item.show_in_diploma_supplement == true ? $t('yes') : $t('no') }}
                                </template>
                                <template #cell(buttons)="row">
                                    <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <div id="dropdownListHead">
                                            <b-dropdown-item @click="deleteRow(row.item)">
                                                <i class="ri-delete-bin-2-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"

// Services
import ProgramBasicFieldServices from "@/services/ProgramBasicFieldServices"

import ProgramOutcome from "@/services/ProgramOutcome"
import ProgramOutcomeDefinition from "@/services/ProgramOutcomeDefinition"


// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        ParameterSelectbox,
    },
    props: {
        program: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            tableData: [],
            tableFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: this.getLocaleField('tyyc_basic_field_name'),
                    label: this.$t('program_basic_fields')
                }
            ],
            isNew: false,
            newFormData: {},
            isDefinition: true,
            definitionForm: {}
        };
    },
    created() {
        this.formData = this.program
        this.getData()
    },
    methods: {
        getData(){
            const config = {
                params: {
                    filter: {
                        program_code: this.program.code
                    },
                    sort: 'id',
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            ProgramBasicFieldServices.getAll(config).then(response => {
                this.tableData = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        newOutcome() {
            this.newFormData = {}
            return this.isNew = !this.isNew
        },

        openDefinitionBox(){
            this.$refs.definitonModal.$refs.commonModal.show()
            this.isDefinition = false
            this.definitionForm = {}
        },

        closeDefinitions(){
            this.isDefinition = true
        },

        async saveDefinition(){
            const isValid = await this.$refs.definitionValidate.validate();
            if (isValid) {
                ProgramOutcomeDefinition.store(this.definitionForm).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.newFormData.program_outcome_definition_id = response.data.data.id
                    this.$refs.definitonModal.$refs.commonModal.hide()
                    this.closeDefinitions()
                }).catch(e => {
                    this.showErrors(e, this.$refs.definitionValidate);
                })
            }
        },

        async save(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formLoading = true
                this.newFormData.program_code = this.program.code
                if(this.newFormData.id) {
                    ProgramOutcome.update(this.newFormData.id, this.newFormData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.formLoading = false
                        this.getData()
                        this.isNew = false
                        this.newFormData = {}
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate);
                        this.formLoading = false
                    })
                } else {
                    ProgramBasicFieldServices.store(this.newFormData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.formLoading = false
                        this.getData()
                        this.isNew = false
                        this.newFormData = {}
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate);
                        this.formLoading = false
                    })
                }
            }
        },

        openUpdateBox(data){
            this.isNew = true
            data.show_in_diploma_supplement = data.show_in_diploma_supplement == true ? 1 : 0
            this.newFormData = data
        },

        deleteRow(data){
            this.$swal
                .fire({
                    title: this.$t('attention'),
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        ProgramBasicFieldServices.del(data.id).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.getData()
                        }).catch(e => {
                            this.showErrors(e)
                        })
                    }
                })
        }

    },
}
</script>
